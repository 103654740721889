export default function IconbWarranty() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M25.1522 21.6961C25.6548 20.5479 25.9324 19.2907 25.9324 17.9729V7.03111L15.3839 3.81104L4.83625 7.03111V17.9729C4.76865 26.463 15.8363 30.9381 22.4252 25.3042"
                stroke="black"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3135 11.1027C16.6097 9.47911 14.0519 9.16851 12.0192 10.1682L14.1648 12.2128L11.8408 14.4274L9.69522 12.3828C8.64619 14.3191 8.97285 16.7572 10.6759 18.3808C12.2775 19.9064 14.6332 20.2712 16.5982 19.4792L23.3731 25.936C24.1652 26.691 25.4496 26.6903 26.2418 25.936C27.034 25.1811 27.034 23.9572 26.2418 23.2023L19.4669 16.7463C20.2972 14.8738 19.9151 12.629 18.3135 11.1027Z"
                stroke="black"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
